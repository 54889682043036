import { match } from "ts-pattern";

import { PriceAdjustment, PriceAdjustmentType } from "~graphql/generated/types";

import { roundToCents } from "./roundToCents";

/**
 * Applies a price adjustment to a currency amount (in cents)
 */
export function adjustPrice(
    cents: number,
    priceAdjustment?: Pick<
        PriceAdjustment,
        "value" | "roundTo" | "adjustmentType"
    >
): number {
    if (!priceAdjustment) return cents;
    const { adjustmentType, value, roundTo } = priceAdjustment;
    const adjustmentValue = match(adjustmentType)
        .with(PriceAdjustmentType.Percentage, () =>
            Math.round((value / 100) * cents)
        )
        .with(PriceAdjustmentType.Flat, () => value)
        .exhaustive();

    // don't use the adjustment value if it's NaN
    if (Number.isNaN(adjustmentValue)) return roundToCents(cents, roundTo);

    const finalValue = roundToCents(cents + adjustmentValue, roundTo);
    return Math.max(0, finalValue);
}

export function adjustPriceDollars(
    dollars: number,
    priceAdjustment?: Pick<
        PriceAdjustment,
        "value" | "roundTo" | "adjustmentType"
    >
): number {
    return adjustPrice(Math.round(dollars * 100), priceAdjustment) / 100;
}
