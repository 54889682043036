/**
 * Rounds a currency amount (in cents) to a specified ending cents value.
 * e.g. round 450 to the nearest 95 = 495, round 420 to the nearest 95 = 395
 */
export function roundToCents(cents: number, roundTo?: number | null): number {
    if (roundTo === null || roundTo === undefined) return cents;
    const shiftedCents = cents - roundTo; // shifting by our roundTo lets us round to the closest dollar
    const closestDollar = Math.round(shiftedCents / 100); // find the closest dollar

    const value = Math.round(closestDollar * 100) + roundTo; // add back the roundTo so the end cents is correct
    return Math.max(value, 0); // clamp to 0 so you don't round like roundCents(5, 95) -> -5
}
